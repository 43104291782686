import { RouteResponse } from './fetch-global-cdn-routes';
import { isValidCdnBaseUrl } from './is-valid-cdn-base-url';

export interface GetAssetUrlsFromRoutesParameters {
    cdnUrl: string;
    path: string;
    routes: RouteResponse;
    label?: string;
}
export function getAssetUrlsFromRoutes(params: GetAssetUrlsFromRoutesParameters): string[]  {
    const {
        cdnUrl,
        path,
        label='official',
        routes,
    } = params;

    if(!isValidCdnBaseUrl(cdnUrl)) {
        throw new Error('ABORT: Invalid CDN URL value: ' + cdnUrl);
    }

    const routePaths = Object.keys(routes);
    const routePath = routePaths.find((rp) => rp.toLocaleLowerCase() === path.toLocaleLowerCase());

    const entries = routes?.[routePath]?.[label] || [];
    return entries.map((entry) => cdnUrl + entry);
}
