import { createCache } from '@alkami/platform-utilities/dist/helpers/cache';

const cache = createCache('Alkami.Globals');
const cdnOverride = null;

export interface GlobalsResponse {
    applicationVersion: string;
    cloudfrontUri: string;
    idleLogoutMinutes: number;
}

export async function fetchGlobals(): Promise<GlobalsResponse> {
    const cachedValue = cache.value;
    let globalValues;

    if (cachedValue) {
        globalValues = JSON.parse(cachedValue);
        Object.assign(globalThis.Alkami.Globals, globalValues);
        return globalValues;
    }

    globalValues = await fetchGlobalResponse();
    globalValues.cloudfrontUri = globalValues.cloudfrontUri || '';

    if (globalThis?.Alkami?.Globals) {
        Object.assign(globalThis.Alkami.Globals, globalValues);
    }

    cache.value = JSON.stringify(globalValues);
    return globalValues;
}

let globalPromise: Promise<GlobalsResponse> = null;
async function fetchGlobalResponse(): Promise<GlobalsResponse> {
    if (globalPromise) {
        return globalPromise;
    }

    globalPromise = (await fetch('/API/Globals'))
        .json()
        .finally(() => {
            globalPromise = null;
        });

    return globalPromise;
}

export function invalidateGlobalCache(): void {
    cache.invalidate();
}