import { SessionStorageCache } from '@alkami/platform-utilities/dist/helpers/cache';

const cache = new SessionStorageCache('Alkami.Globals.CacheBustingValue');

export function generateCacheBustingValue() {
    if(!cache.value) {
        cache.value = Date.now().toString(10);
    }

    return cache.value;
}

export function invalidateCacheBustingValue() {
    cache.invalidate();
}