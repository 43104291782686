
import { createCache } from '@alkami/platform-utilities/dist/helpers/cache';
import { isValidCdnBaseUrl } from './is-valid-cdn-base-url';

export interface FetchGlobalCdnRoutesParameters {
    orbVersion: string;
    cdnUrl: string;
}

export interface RouteResponse {
    [key: string]: Record<string, string[]>;
}

export async function fetchGlobalCdnRoutes(params: FetchGlobalCdnRoutesParameters): Promise<RouteResponse> {
    const { orbVersion, cdnUrl: cdnBaseUrl } = params;
    const version = orbVersion.split('.', 2).join('.');
    const key = `Alkami.CDN.Routes.${cdnBaseUrl.replace('https://', '').split('/', 1)[0]}.${version}`;

    // Don't want anybody abusing this method for nefarious purposes
    if (!isValidCdnBaseUrl(cdnBaseUrl)) {
        throw new Error('ABORT: Invalid CDN URL value: ' + cdnBaseUrl);
    }

    const cache = createCache(key);
    const cachedValue = cache.value;

    if (cachedValue) {
        return JSON.parse(cachedValue);
    }

    const response = (await (await fetch(`${cdnBaseUrl}global-cdn/${version}/routes.json`)).text());
    cache.value = response;

    return JSON.parse(response);
}