import components from '@alkami/platform-components';
import { dom, helpers, patterns, utils } from '@alkami/platform-utilities';

import * as cdn from './cdn';
import * as compliance from './compliance';
import * as globals from './globals';

const { widgetHeader, flashBanner, primaryNavigation } = components;
const global = globalThis || (window as any);

const Utils = {
    CookieHelper: utils.cookie,
    CurrencyHelper: utils.currency,
    DateHelper: utils.date,
    StorageHelper: utils.storage,
    StringHelper: utils.string,
};

const Alkami = {
    Dom: dom,
    Cdn: cdn,
    Compliance: compliance,
    FlashBanner: flashBanner,
    Helpers: helpers.default,
    Patterns: patterns,
    PrimaryNavigation: components.primaryNavigation.service,
    WidgetHeader: widgetHeader,
    WidgetNav: components.primaryNavigation.service,
    WidgetTitleBar: widgetHeader,
    Utils,
    Globals: globals,
};

// If we are running this by itself, we need to add Alkami.
if (!global.Alkami) {
    global.Alkami = {};
}

// Just in case some poor Joe has added to these namespaces.
const globalHelpers = Object.assign({}, global.Alkami.Helpers, Alkami.Helpers);
const globalUtils = Object.assign({}, global.Alkami.Utils, Alkami.Utils);
const globalGlobals = Object.assign({}, global.Alkami.Globals, Alkami.Globals);
const globalCompliance = Object.assign(
    {},
    global.Alkami.Compliance,
    Alkami.Compliance,
);

Object.assign(global.Alkami, Alkami, {
    Compliance: globalCompliance,
    Helpers: globalHelpers,
    Utils: globalUtils,
    Globals: globalGlobals,
});

const hasNewNavMenu = Object.keys(window.sessionStorage || {}).find((key) =>
    key.toLowerCase().startsWith('alkami.navmenu'),
);

if (!hasNewNavMenu) {
    primaryNavigation.service.init();
}

export default Alkami;
