import {
    FdicConfiguration,
    FdicConfigurationError,
    FdicConfigurationResponse,
} from './types';

const FDIC_CONFIGURATION_KEY = 'fdicConfiguration';
const FDIC_CONFIGURATION_API = '/afx/v2/compliance/fdic-configuration';

const defaultConfig: FdicConfiguration = {
    desktop: {
        footer: {
            isEnabled: false,
        },
        header: {
            isEnabled: false,
        },
        login: {
            isEnabled: false,
        },
    },
    mobile: {
        footer: {
            isEnabled: false,
        },
        header: {
            isEnabled: false,
        },
        login: {
            isEnabled: false,
        },
    },
    page: {
        isEnabled: false,
    },
};

const isFdicConfigurationError = (
    value: unknown,
): value is FdicConfigurationError =>
    typeof value === 'object' && 'error' in value && 'message' in value;

const fetchFdicConfiguration = async (): Promise<void> => {
    try {
        const response = await fetch(FDIC_CONFIGURATION_API);
        const data: FdicConfigurationResponse = await response.json();
        if (isFdicConfigurationError(data)) {
            throw new Error(data.message);
        }
        localStorage.setItem(FDIC_CONFIGURATION_KEY, JSON.stringify(data));
    } catch (error) {
        console.error(error);
    }
};

void fetchFdicConfiguration();

/**
 * Fetches and returns the FDIC Configuration.
 *
 * @returns {FdicConfiguration} - FDIC Configuration settings
 */
export const getFdicConfiguration = (): FdicConfiguration => {
    const fdicConfig = localStorage.getItem(FDIC_CONFIGURATION_KEY);

    if (!fdicConfig) {
        return defaultConfig;
    }

    try {
        return JSON.parse(fdicConfig);
    } catch {
        return defaultConfig;
    }
};
