import { loadScript, loadStylesheet } from '@alkami/platform-utilities/dist/dom';
import { fetchAssetUrls } from '.';
import { generateCacheBustingValue } from './generate-cache-busting-value';

export async function scriptWithCacheExpiration(path: string, options: AssetWithCacheExpirationOptions = {}): Promise<HTMLScriptElement[]> {
    const assetUrls = await assetWithCacheExpiration(path, options);
    return (await Promise.all(assetUrls.map(async (url) => {
        let element: HTMLScriptElement = document.querySelector(`script[src="${url}"]`);
        if(element) {
            return Promise.resolve(element);
        }

        try {
            element = (await loadScript(url)).target as HTMLScriptElement;
        } catch(e) {
            throw (e as Event).target;
        }

        return element;
    }))) as HTMLScriptElement[];
}

export async function cssWithCacheExpiration(path: string, options: AssetWithCacheExpirationOptions = {}): Promise<HTMLLinkElement[]> {
    const assetUrls = await assetWithCacheExpiration(path, options);
    return (await Promise.all(assetUrls.map(async (url) => {
        let element: HTMLLinkElement = document.querySelector(`link[href="${url}"]`);
        if(element) {
            return Promise.resolve(element);
        }

        try {
            element = (await loadStylesheet(url)).target as HTMLLinkElement;
        } catch(e) {
            throw (e as Event).target;
        }

        return element;
    }))) as HTMLLinkElement[];
}

export interface AssetWithCacheExpirationOptions {
    label?: string;
}

export async function assetWithCacheExpiration(path: string, options: AssetWithCacheExpirationOptions = {}): Promise<string[]> {
    const { label = 'official' } = options;

    const assetUrls = await fetchAssetUrls({
        path,
        label,
    });

    if (assetUrls.length === 0) {
        assetUrls.push(path + '?' + generateCacheBustingValue());
    }

    return assetUrls;
}