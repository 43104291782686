import { fetchGlobals, GlobalsResponse } from '../globals/globals-api';
import { fetchGlobalCdnRoutes } from './fetch-global-cdn-routes';
import { getAssetUrlsFromRoutes } from './get-asset-urls-from-routes';

export interface FetchAssetlUrlParameters {
    path: string;
    label?: string;
}

export async function fetchAssetUrls(params: FetchAssetlUrlParameters): Promise<string[]> {
    let { path } = params;
    const { label = 'official' } = params;

    if (path.startsWith('~')) {
        path = path.replace('~', '');
    }
    if (!path.startsWith('/')) {
        path = '/' + path;
    }

    try {
        const {
            cloudfrontUri,
            applicationVersion: orbVersion,
        } = await fetchGlobals();

        const cdnBase = cloudfrontUri.endsWith('/') ? cloudfrontUri : cloudfrontUri + '/';
        const cdnUrl = cdnBase + 'cdn/';
        const routes = await fetchGlobalCdnRoutes({
            cdnUrl,
            orbVersion,
        });

        return getAssetUrlsFromRoutes({
            cdnUrl,
            path,
            label,
            routes,
        });
    } catch (err) {
        const error = err as Error;
        console.error(error);

        return [path];
    }
}
